import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useParams,
  Link,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { Lock, AlertTriangle, FileWarning, Home } from "lucide-react";

const AccessDeniedPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center">
        <Lock className="w-24 h-24 mx-auto text-red-500 mb-4" />
        <h1 className="text-4xl font-bold text-gray-800 mb-2">Access Denied</h1>
        <p className="text-xl text-gray-600 mb-8">
          You do not have permission to access this page.
        </p>
        {/* <Link
          to="/qr-viewer"
          className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300"
        >
          Go to QR Code Viewer
        </Link> */}
      </div>
    </div>
  );
};

const ErrorMessage = ({ icon: Icon, title, message }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center">
        <Icon className="w-24 h-24 mx-auto text-yellow-500 mb-4" />
        <h2 className="text-4xl font-bold text-gray-800 mb-2">{title}</h2>
        <p className="text-xl text-gray-600">{message}</p>
      </div>
    </div>
  );
};

const QRCodePDFViewer = () => {
  const { filename } = useParams();
  const [pdfStatus, setPdfStatus] = useState("loading");
  const pdfUrl = `/qr-codes/${filename}`;

  useEffect(() => {
    let timer;
    const checkPdfExists = async () => {
      try {
        const response = await fetch(pdfUrl, { method: "HEAD" });
        if (!response.ok) {
          throw new Error("PDF not found");
        }
        setPdfStatus("found");
        timer = setTimeout(() => {
          const iframe = document.querySelector("iframe");
          if (
            iframe &&
            iframe.contentDocument &&
            iframe.contentDocument.body.childElementCount === 0
          ) {
            setPdfStatus("not-found");
          }
        }, 3000);
      } catch (error) {
        setPdfStatus("not-found");
      }
    };
    checkPdfExists();
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [pdfUrl]);

  if (pdfStatus === "loading") {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <p className="text-xl text-gray-600">Loading...</p>
      </div>
    );
  }

  if (pdfStatus === "not-found") {
    return (
      <ErrorMessage
        icon={AlertTriangle}
        title="Warning: QR not found"
        message="The requested QR code PDF could not be found."
      />
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full h-screen max-w-4xl">
        <iframe src={pdfUrl} className="w-full h-full" title="PDF Viewer">
          <ErrorMessage
            icon={FileWarning}
            title="PDF Viewer Not Supported"
            message="Your browser does not support PDFs. Please download the PDF to view it."
          />
        </iframe>
      </div>
    </div>
  );
};

const QRCodeFileHandler = () => {
  const { filename } = useParams();
  if (!filename || !filename.endsWith(".pdf")) {
    return <Navigate to="/qr-viewer" replace />;
  }
  return <QRCodePDFViewer />;
};


const QRCodeViewerPage = () => {
  const [qrCodes, setQrCodes] = useState([]);

  const fetchQRCodes = () => {
    fetch("/api/qr-codes")
      .then((response) => response.json())
      .then((data) => setQrCodes(data))
      .catch((error) => console.error("Error fetching QR codes:", error));
  };

  useEffect(() => {
    fetchQRCodes();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-8">
      <Home className="w-24 h-24 text-blue-500 mb-4" />
      <h1 className="text-4xl font-bold text-gray-800 mb-8">
        QR Code PDF Viewer
      </h1>
      <button
        onClick={fetchQRCodes}
        className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
      >
        Refresh QR Codes
      </button>
      {qrCodes.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {qrCodes.map((filename) => (
            <Link
              key={filename}
              to={`/qr-codes/${filename}`}
              className="block p-4 bg-white rounded-lg shadow hover:shadow-md transition duration-300"
            >
              {filename}
            </Link>
          ))}
        </div>
      ) : (
        <p className="text-xl text-gray-600">No QR code PDFs available.</p>
      )}
    </div>
  );
};


const App = () => {
  return (
    <Router>
      <Helmet>
        <title>{process.env.REACT_APP_TITLE || "QR Code Viewer"}</title>
      </Helmet>
      <Routes>
        <Route path="/" element={<AccessDeniedPage />} />
        <Route path="/qr-viewer" element={<QRCodeViewerPage />} />
        <Route path="/qr-codes/:filename" element={<QRCodeFileHandler />} />
        <Route path="*" element={<AccessDeniedPage />} />
      </Routes>
    </Router>
  );
};

export default App;
